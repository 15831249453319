import React from 'react';

const YourTeam = ({ members }) => {
  return (
    <div className="card">
      <div className="card-body">
        <h5 className="font-weight-bolder">Your organization's members</h5>
        <hr className="dark horizontal my-4" />
        <div className="table-responsive p-0">
          {!members || members.length === 0 ? (
            <p>No Team member</p>
          ) : (
            <table className="table table-bordered table-hover table-sm table-word-break">
              <thead>
                <tr>
                  <th className="text-uppercase text-secondary">Name</th>
                  <th className="text-uppercase text-secondary">Email</th>
                </tr>
              </thead>
              <tbody>
                {members.map((member, index) => (
                  <tr key={member.uuid + index} className="text-left">
                    <td className="align-middle">
                      {member.firstName} {member.lastName}
                    </td>
                    <td className="align-middle">{member.username}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default YourTeam;
