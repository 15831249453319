import React from 'react';
import voca from 'voca';
import { Link } from 'react-router-dom';
import { createTooltipInfo, FREE, showProjectPlan } from '../../common/constants';
import TooltipInfo from '../../../common/TooltipInfo';
import { formatProjectDate } from '../../common/utils/date-utils';

const ProjectDetailOld = ({
  index,
  project,
  protocol,
  builderSurface,
  exchange_surface,
  token,
}) => {
  const {
    projectType,
    projectState,
    name,
    uuid,
    dateCreated,
    framework,
    seoName,
    domainName,
    url,
  } = project;
  const description = createTooltipInfo(project);
  const projectStatus = projectState && projectState === 'PAID' ? 'Publishable' : 'Unpublishable';
  const showProjectLink = () => {
    if (!projectType || projectType === FREE) {
      return '';
    }
    let urlToOpen = '';
    if (domainName && domainName !== url) {
      urlToOpen = `https://${domainName}`;
    } else {
      urlToOpen = `${protocol}://${seoName}.${exchange_surface}`;
    }

    return (
      <>
        <a href={urlToOpen} target="_blank" title="View Live App" className="text-sm">
          Visit Project{' '}
          <i
            className="fas fa-up-right-from-square"
            style={{ cursor: 'pointer', fontSize: '15px' }}
          ></i>
        </a>
      </>
    );
  };
  return (
    <div className="card">
      <div className="card-header p-2 ps-3">
        <div className="d-flex justify-content-between">
          <div>
            <p
              className={`mb-0 text-capitalize ${
                projectState === 'PAID' ? 'text-success' : 'text-muted opacity-6'
              } font-italic font-weight-normal`}
            >
              {voca.titleCase(projectStatus)}
            </p>
            <h4 className="mb-0" title={`${voca.titleCase(name)}`}>
              {voca.truncate(voca.titleCase(name), 25)}
            </h4>
          </div>
          <div className="d-flex justify-content-between">
            {/* <div className="icon icon-md icon-shape bg-gradient-dark shadow-dark shadow text-center border-radius-lg"> */}
            <div className="icon icon-md icon-shape text-center border-radius-lg">
              <Link
                to={`/${uuid}/project-detail`}
                className="setting-icon"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Project Details"
              >
                <i
                  className="fa fa-cog link-dark-blue opacity-10"
                  style={{ cursor: 'pointer', fontSize: '21px' }}
                ></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <hr className="dark horizontal my-0"></hr>
      <div className="card-body p-3">
        <div className="timeline timeline-one-side">
          <div className="timeline-block mb-3">
            <span className="timeline-step">
              <i className="material-icons text-primary text-gradient">watch_later</i>
            </span>
            <div className="timeline-content">
              <h6 className="text-dark text-sm font-weight-bold mb-0">
                {formatProjectDate(new Date(dateCreated))}
              </h6>
            </div>
          </div>
          <div className="timeline-block mb-3">
            <span className="timeline-step">
              <i className="material-icons text-danger text-gradient">code</i>
            </span>
            <div className="timeline-content">
              <h6 className="text-dark text-sm font-weight-bold mb-0">
                {framework ? `${framework.name} ${framework.version}` : 'N/A'}
              </h6>
            </div>
          </div>
          <div className="timeline-block mb-3">
            <span className="timeline-step">
              <i className="material-icons text-primary text-gradient">cloud</i>
            </span>
            <div className="timeline-content d-flex justify-content-between">
              <div className="d-flex">
                <h6 className="text-dark text-sm font-weight-bold mb-0">
                  {showProjectPlan(project)}
                </h6>
                &nbsp;&nbsp;
                <TooltipInfo
                  name={index}
                  description={description}
                  showHeader={true}
                  showArrow={true}
                />
              </div>

              {showProjectLink()}
            </div>
          </div>
        </div>
        <hr className="dark horizontal"></hr>
        <div className="d-flex mx-2 justify-content-between">
          <a
            href={`${protocol}://${seoName}.${builderSurface}editor/${1}/v/${uuid}/loader?token=${token}`}
            target="_blank"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Configure Project"
            className="btn btn-sm btn-success text-white"
          >
            <i className="fas fa-cog " style={{ fontSize: '12px' }}></i> Project Studio
          </a>
          <a
            href={`${protocol}://${seoName}.preview.${exchange_surface}`}
            target="_blank"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="View Live App"
            className="btn btn-sm view-button"
          >
            <i className="fas fa-eye" style={{ fontSize: '11px' }}></i> Preview
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailOld;
