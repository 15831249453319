/**
 * Professional Plan -> BUILDER_1
 * Growth Plan -> BUILDER_2
 * Scale Plan -> BUILDER_3
 * Enterprise Plan -> BUILDER_4
 * */
export const orgPriceDetails = {
  test: {
    BUILDER_1: 'price_1QMqsuC8MSObRTqDzilu2KQy', // Professional Plan
    BUILDER_2: 'price_1QMqtPC8MSObRTqDoD8VAWNQ', // Growth Plan
  },
  live: {
    BUILDER_1: 'price_1QMtHtAxNvKBf8ANPWpIAi8E', // Professional Plan
    BUILDER_2: 'price_1QMtKdAxNvKBf8ANDS4fuCA7', // Growth Plan
  },
};

/**
 * Starter Plan -> PAID_1 (Deprecated plan) -> No Stripe Plan
 * Launch Plan -> PAID_2 -> Stripe Plan: PAID_1
 * Grow Plan -> PAID_3 -> Stripe Plan: PAID_2
 * Scale Plan -> PAID_4
 * Enterprise Plan -> PAID_5
 * */
export const projectPriceDetails = {
  test: {
    PAID_1: 'price_1QO02XC8MSObRTqDllAJSbIl', // Launch Plan
    PAID_2: 'price_1QO02vC8MSObRTqDFRym015L', // Grow Plan
  },
  live: {
    PAID_1: 'price_1QMtRiAxNvKBf8ANTY1eh1In', // Launch Plan
    PAID_2: 'price_1QMtRiAxNvKBf8ANTY1eh1In', // Grow Plan
  },
};
