import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import Layout from './Layout';
import CurrentMonthDetail from './bill/CurrentMonthDetail';
import Invoices from './bill/Invoices';
import CustomLoader from '../common/CustomLoader';

export class Bill extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  state = {
    currentItemPage: 1,
    perItemPage: 20,
    currentInvoicePage: 1,
    perInvoicePage: 20,
  };

  componentDidMount() {
    const { actions } = this.props;
    const { currentItemPage, currentInvoicePage, perItemPage, perInvoicePage } = this.state;
    actions.fetchLineItems({ page: currentItemPage - 1, size: perItemPage });
    actions.fetchInvoice({ page: currentInvoicePage - 1, size: perInvoicePage });
  }

  handleItemPageChange = (page) => {
    const { actions, home } = this.props;
    const { perItemPage } = this.state;

    const newPage = Math.max(1, Math.min(page, Math.ceil(home.lineItemMetadata.totalPages)));

    this.setState({ currentItemPage: newPage }, () => {
      actions.fetchLineItems({ page: newPage - 1, size: perItemPage });
    });
  };

  handleInvoicePageChange = (page) => {
    const { actions, home } = this.props;
    const { perInvoicePage } = this.state;

    const newPage = Math.max(1, Math.min(page, Math.ceil(home.invoiceMetadata.totalPages)));

    this.setState({ currentInvoicePage: newPage }, () => {
      actions.fetchInvoice({ page: newPage - 1, size: perInvoicePage });
    });
  };

  showTransactions = () => {
    const { home } = this.props;
    const { lineItemMetadata, fetchLineItemsPending } = home;
    const { currentItemPage } = this.state;
    if (fetchLineItemsPending) {
      return (
        <div className="text-center">
          <CustomLoader loading={fetchLineItemsPending} size="20px" color="#303453" />;
        </div>
      );
    }
    if (!lineItemMetadata) {
      return '';
    }
    const { content, totalPages } = lineItemMetadata;

    return (
      <CurrentMonthDetail
        lineItems={content}
        currentItemPage={currentItemPage}
        totalPages={totalPages}
        handleItemPageChange={this.handleItemPageChange}
      />
    );
  };

  showInvoices = () => {
    const { home } = this.props;
    const { invoiceMetadata, fetchInvoicePending } = home;
    const { currentInvoicePage } = this.state;

    if (fetchInvoicePending) {
      return (
        <div className="text-center">
          <CustomLoader loading={fetchInvoicePending} size="20px" color="#303453" />;
        </div>
      );
    }
    if (!invoiceMetadata) {
      return '';
    }
    const { content, totalPages } = invoiceMetadata;

    return (
      <Invoices
        invoices={content}
        currentInvoicePage={currentInvoicePage}
        totalPages={totalPages}
        handleInvoicePageChange={this.handleInvoicePageChange}
      />
    );
  };

  render() {
    const { home } = this.props;
    const { invoices } = home;

    return (
      <Layout currentPage="Invoices">
        <div className="mt-5">
          <div className="mx-3 mx-md-4">
            <div className="row gx-4 mb-2">
              <div className="col-6">
                <div className="card h-100 mb-4">
                  <div className="card-header pb-0 px-3">
                    <div className="row">
                      <div className="col-md-6">
                        <h4 className="mb-0">Your Invoices</h4>
                      </div>
                    </div>
                  </div>
                  {this.showInvoices()}
                </div>
              </div>
              <div className="col-6">
                <div className="card h-100 mb-4">
                  <div className="card-header pb-0 px-3">
                    <div className="row">
                      <div className="col-md-6">
                        <h4 className="mb-0">Your Transactions</h4>
                      </div>
                    </div>
                  </div>
                  {this.showTransactions()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Bill);
