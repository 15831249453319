import React from 'react';
import HostingPlanDetail from './HostingPlanDetail';
import { projectPriceDetails } from '../../common/utils/price-utils';
import HTMLReactParser from 'html-react-parser';
import { FREE, getCloudPlanFeature, PAID_1, PAID_5 } from '../../common/constants';

const ProjectHostingPlan = ({ project, handleUpgrade, cancelUpgrade }) => {
  const env = process.env.REACT_APP_ENV;
  const { projectType } = project;

  const getCustom = () => {
    return (
      <div className="text-center">
        <h5 className="price">Custom Pricing</h5>
      </div>
    );
  };

  const showProjectCancellation = () => {
    if (projectType !== 'FREE') {
      return (
        <div className="col-xl-4 col-md-4 mb-4">
          <div className="card card-blog card-plain shadow-lg move-on-hover">
            <div className="card-body p-3">
              <h4 className="mb-3 text-center">No Hosting</h4>
              <div className="text-center">
                <h5 className="d-inline-block">
                  $<span className="price">0</span>
                </h5>
                <h5 className="price-detail-color d-inline-block my-2">/month</h5>
                <div className="text-center mb-2">{HTMLReactParser(getCloudPlanFeature(FREE))}</div>
                <div className="d-flex justify-content-center mt-6">
                  <button type="button" className="btn btn-danger" onClick={cancelUpgrade}>
                    <i className="material-icons text-lg position-relative">cancel</i>&nbsp; Cancel
                    Subscription
                  </button>
                </div>
              </div>
              <hr className="dark horizontal my-3" />
              <div className="text-center mb-3">
                <a href="https://drapcode.com/pricing" target="_blank" className="social-icon">
                  <span className="price-color">View Details</span>&nbsp;
                  <i className="fas fa-info-circle price-detail-color my-2 text-decoration-none"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  /**
   * Starter Plan -> PAID_1 (It is a deprecated plan)
   * Launch Plan -> PAID_2
   * Grow Plan -> PAID_3
   * Scale Plan -> PAID_4
   * Enterprise Plan -> PAID_5
   * */
  return (
    <div className="row mb-6">
      {showProjectCancellation()}
      <div className={projectType !== 'FREE' ? 'col-xl-4 col-md-4 mb-4' : 'col-xl-4 col-md-6 mb-4'}>
        <HostingPlanDetail
          planName="Launch Plan"
          price={50}
          drapcodePlan="PAID_2"
          stripePlan={projectPriceDetails[env]['PAID_1']}
          projectType={projectType}
          handleUpgrade={handleUpgrade}
        />
      </div>
      <div className={projectType !== 'FREE' ? 'col-xl-4 col-md-4 mb-4' : 'col-xl-4 col-md-6 mb-4'}>
        <HostingPlanDetail
          planName="Grow Plan"
          price={150}
          drapcodePlan="PAID_3"
          stripePlan={projectPriceDetails[env]['PAID_2']}
          projectType={projectType}
          handleUpgrade={handleUpgrade}
        />
      </div>
      <div className={projectType !== 'FREE' ? 'col-xl-4 col-md-4 mb-4' : 'col-xl-4 col-md-6 mb-4'}>
        <HostingPlanDetail
          planName="Scale Plan"
          price={650}
          drapcodePlan="PAID_4"
          stripePlan={''}
          projectType={projectType}
          handleUpgrade={''}
          showContactUs={true}
        />
      </div>

      <div className={projectType !== 'FREE' ? 'col-xl-4 col-md-4 mb-4' : 'col-xl-4 col-md-6 mb-4'}>
        <div className="card card-blog card-plain shadow-lg move-on-hover">
          <div className="card-body p-3">
            <h4 className="mb-3 text-center">Enterprise Plan</h4>
            {getCustom()}
            <div className="text-center mb-2">{HTMLReactParser(getCloudPlanFeature(PAID_5))}</div>
            <div className="d-flex justify-content-center mt-4">
              <a href="https://drapcode.com/contact-us" target="_blank">
                <button type="button" className="btn btn-success text-white">
                  <i className="material-icons">mail</i> Contact Us
                </button>
              </a>
            </div>
            <hr className="dark horizontal my-3" />
            <div className="text-center mb-3">
              <a href="https://drapcode.com/pricing" target="_blank" className="social-icon">
                <span className="price-color">View Details</span>&nbsp;
                <i className="fas fa-info-circle price-detail-color my-2 text-decoration-none"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectHostingPlan;
