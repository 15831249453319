export const FREE = 'FREE';
export const PAID_1 = 'PAID_1';
export const PAID_2 = 'PAID_2';
export const PAID_3 = 'PAID_3';
export const PAID_4 = 'PAID_4';
export const PAID_5 = 'PAID_5';
export const PAID_6 = 'PAID_6';
export const PAID_7 = 'PAID_7';
export const BUILDER_FREE = 'BUILDER_FREE';
export const BUILDER_1 = 'BUILDER_1';
export const BUILDER_2 = 'BUILDER_2';
export const BUILDER_3 = 'BUILDER_3';
export const BUILDER_4 = 'BUILDER_4';
export const UNLIMITED = 'UNLIMITED';

export const projectPermissions = [
  { label: 'Project not allowed to clone', value: 'CLONE_NOT_ALLOWED' },
  {
    label: 'Project allowed to clone after approval',
    value: 'CLONE_WITH_PERMISSION',
  },
  {
    label: 'Project can be clone without permission',
    value: 'CLONE_WITHOUT_PERMISSION',
  },
];

export const bsFrameworkVersionOptions = [
  { value: 'BOOTSTRAP_4_6', version: '4.6', label: 'Bootstrap 4' },
  { value: 'BOOTSTRAP_5_2', version: '5.2', label: 'Bootstrap 5' },
];

export const OrgPlanOptions = [
  { value: BUILDER_FREE, label: 'Free Plan' },
  {
    value: BUILDER_1,
    label: 'Professional Plan',
  },
  {
    value: BUILDER_2,
    label: 'Growth Plan',
  },
  {
    value: BUILDER_3,
    label: 'Scale Plan',
  },
  {
    value: BUILDER_4,
    label: 'Enterprise',
  },
  {
    value: UNLIMITED,
    label: 'Developer',
  },
];

export const ProjectPlanOptions = [
  { value: FREE, label: 'No Hosting' },
  {
    value: PAID_1,
    label: 'Starter Plan',
  },
  {
    value: PAID_2,
    label: 'Launch Plan',
  },
  {
    value: PAID_3,
    label: 'Grow Plan',
  },
  {
    value: PAID_4,
    label: 'Scale Plan',
  },
  {
    value: PAID_5,
    label: 'Enterprise 1',
  },
  {
    value: PAID_6,
    label: 'Enterprise 2',
  },
  {
    value: PAID_7,
    label: 'Enterprise 3',
  },
];

export const getStudioPlanFeature = (type) => {
  let features = '';
  switch (type) {
    case BUILDER_1:
      features = `
          <div>InBuilt Database</div>
          <div>25 Dynamic Web Pages</div>
          <div>5 Plugins</div>
          <div>100% White Label</div>
          <div>Full Code Export</div>
          <div>1 Test Project</div>
        ...and more.
      `;
      break;
    case BUILDER_2:
      features = `
          <div>Everything in Professional Plan</div>
          <div>50 Dynamic Web Pages</div>
          <div>10 Plugins</div>
          <div>2 Test Projects</div>
          <div>PWA Features</div>
          <div>Email Support</div>
        ...and more.
      `;
      break;
    case BUILDER_3:
      features = `
          <div>Everything in Growth Plan</div>
          <div>100 Dynamic Web Pages</div>
          <div>15 Plugins</div>
          <div>Two-Factor Auth (2FA)</div>
          <div>Full Code Export</div>
          <div>Priority Email Support</div>
        ...and more.
      `;
      break;
    case BUILDER_4:
      features = `
          <div>Everything in Scale Plan</div>
          <div>Unlimited Web Pages</div>
          <div>Unlimited Plugins</div>
          <div>Custom Features</div>
          <div>Dedicated Account Manager</div>
          <div>24×7×365 Enterprise Support</div>
        ...and more.
      `;
      break;
    default:
      break;
  }
  return features;
};

export const getCloudPlanFeature = (type) => {
  let features = '';
  switch (type) {
    case FREE:
      features = `
          <div>You'll loose all cloud hosting benefits attached to your project</div>
          <div>Project cannot be published</div>
          <div>Access to Live project will be revoked</div>
          <div>Only Preview/Test environment will be available</div>
      `;
      break;
    case PAID_1:
    case PAID_2:
      features = `
          <div>Unlimited Data Records 1 GB of Disk Storage for Data</div>
          <div>4 GB of File/Images Storage</div>
          <div>Custom Domain</div>
          <div>Free SSL Certificate</div>
          <div>2 GB Memory (RAM)</div>
          <div>2 vCPUs Shared Resources</div>
        ...and more.
      `;
      break;
    case PAID_3:
      features = `
          <div>Unlimited Data Records 5 GB of Disk Storage for Data</div>
          <div>15 GB of File/Images Storage</div>
          <div>Custom Domain</div>
          <div>Custom SSL Certificate</div>
          <div>4 GB Memory (RAM)</div>
          <div>2 vCPUs Shared Resources</div>
        ...and more.
      `;
      break;
    case PAID_4:
      features = `
          <div>Unlimited Data Records 20 GB of Disk Storage for Data</div>
          <div>80 GB of File/Images Storage</div>
          <div>Custom Domain</div>
          <div>Custom SSL Certificate</div>
          <div>12 GB (8 + 4) Memory (RAM)</div>
          <div>6 vCPUs (4 + 2) Dedicated Resources</div>
        ...and more.
      `;
      break;
    case PAID_5:
      features = `
          <div>Unlimited Data Records Custom Disk Storage for Data</div>
          <div>Custom File/Images Storage</div>
          <div>Custom Domain</div>
          <div>Custom SSL Certificate</div>
          <div>Custom Memory (RAM)</div>
          <div>Custom vCPUs Dedicated Resources</div>
        ...and more.
      `;
      break;
    default:
      break;
  }

  return features;
};

export const getProjectTypeLabel = (value) => {
  if (!value) {
    return 'N/A';
  }
  const planOption = ProjectPlanOptions.find((option) => option.value === value);
  return planOption ? planOption.label : 'N/A';
};

export const getOrgSubscriptionLabel = (value) => {
  const planOption = OrgPlanOptions.find((option) => option.value === value);
  return planOption ? planOption.label : 'N/A';
};

export const showProjectPlan = (project) => {
  const free = ProjectPlanOptions.find((plan) => plan.value === 'FREE').label;
  if (!project || !project.projectType) {
    return free;
  }
  const projectPlan = ProjectPlanOptions.find((plan) => plan.value === project.projectType);
  // if (!projectPlan) {
  //   return free;
  // }
  return projectPlan.label;
};

export const showProjectPlanFeature = (project) => {
  const free = ProjectPlanFeatureOptions.find((plan) => plan.value === 'FREE').feature;
  if (!project || !project.projectType) {
    return free;
  }
  const projectPlan = ProjectPlanFeatureOptions.find((plan) => plan.value === project.projectType);
  if (!projectPlan) {
    return free;
  }
  return projectPlan.feature;
};

export const getOrdinalSuffix = (day) => {
  if (day >= 11 && day <= 13) {
    return 'th';
  }
  const lastDigit = day % 10;
  switch (lastDigit) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const createTooltipInfo = (project) => {
  const { projectType, uuid } = project;

  if (!projectType || projectType === FREE) {
    return `Hosting: This project don't have any hosting plan. Only Preview/Test environment will be available.`;
  } else if (projectType === PAID_1) {
    return `Hosting: This project is in Starter Plan. Your application will be hosted to the cloud according to Starter Plan. Please checkout our hosting plan for more details.`;
  } else if (projectType === PAID_2) {
    return `Hosting: This project is in Launch Plan. Your application will be hosted to the cloud according to Launch Plan. Please checkout our hosting plan for more details.`;
  } else if (projectType === PAID_3) {
    return `Hosting: This project is in Grow Plan. Your application will be hosted to the cloud according to Grow Plan. Please checkout our hosting plan for more details.`;
  } else if (projectType === PAID_4) {
    return `Hosting: This project is in Scale Plan. Your application will be hosted to the cloud according to Scale Plan. Please checkout our hosting plan for more details.`;
  } else if (projectType === PAID_5) {
    return `Hosting: This project is in Enterprise Plan. Please checkout our hosting plan for more details.`;
  }
};

export const prepareProjectMessage = (organization, projectStats) => {
  const { projectCountAllowed, maxFreeProjects, maxPaidProjects, subscription } = organization;
  if (subscription === 'BUILDER_FREE') {
    return '';
  } else if (subscription === 'UNLIMITED') {
    return '';
  }
  const { freeProjects, paidProjects } = projectStats || {};
  let message = ``;
  const currentPlan = getOrgSubscriptionLabel(subscription);
  if (freeProjects < maxFreeProjects) {
    console.log('Free project available.');
    const availableProjectCount = maxFreeProjects - freeProjects;
    // message = `With your current ${currentPlan}, you can create ${availableProjectCount} more project(s) for free.`;
    message = `In <b><em>${currentPlan}</em></b>, you can have ${availableProjectCount} additional Unpublishable/Test project(s).`;
    message = `${message} ${preparePaidProjectMessage(
      projectCountAllowed,
      maxPaidProjects,
      paidProjects,
      true,
    )}`;
  } else {
    message = `You have exhausted the Unpublishable/Test project limit in your current <b><em>${currentPlan}</em></b>.`;
    // message = 'Your free project limit reached.';
    console.log('Free project limit reached. Check for paid projects.');
    message = `${message} ${preparePaidProjectMessage(
      projectCountAllowed,
      maxPaidProjects,
      paidProjects,
      false,
    )}`;
  }
  return message;
};

const preparePaidProjectMessage = (
  projectCountAllowed,
  maxPaidProjects,
  paidProjects,
  isFreeAvailable = false,
) => {
  let message = '';
  if (paidProjects < projectCountAllowed) {
    // message = `You can mark ${
    //   projectCountAllowed - paidProjects
    // } project/s as a paid (included in your subscription).`;

    message = `Additionally, you can create up to ${
      projectCountAllowed - paidProjects
    } Publishable/Live project(s).`;
    return message;
  }
  if (paidProjects < maxPaidProjects) {
    // message = `You can have ${maxPaidProjects - paidProjects} more paid project/s.`;

    message = `Additionally, you can have up to ${
      maxPaidProjects - paidProjects
    } Publishable/Live project(s).`;
  } else {
    if (!isFreeAvailable) {
      // message = `You have reached projects limit. Please contact us.`;
      // message = `You have reached the project limit. Please contact us for further assistance.`;
    }
  }
  return message;
};

export const prepareProjectStats = (organization, projectStats) => {
  const { projectCountAllowed, maxFreeProjects, maxPaidProjects } = organization;
  const { freeProjects, paidProjects } = projectStats;
  if (freeProjects < maxFreeProjects) {
    return { success: true };
  }
  return preparePaidProjectStats(projectCountAllowed, maxPaidProjects, paidProjects, false);
};

const preparePaidProjectStats = (
  projectCountAllowed,
  maxPaidProjects,
  paidProjects,
  isFreeAvailable = false,
) => {
  if (paidProjects < projectCountAllowed) {
    return {
      success: false,
      // message: `Free project limit reached. Mark any project paid(included in your subscription)`,
      message: `You have exhausted the Unpublishable/Test project limit. Please change any Unpublishable/Test project to Publishable/Live (included in your subscription).`,
    };
  }

  if (paidProjects < maxPaidProjects) {
    return {
      success: false,
      // message: `Free project limit reached. Mark any project paid.`,
      message: `You have exhausted the Unpublishable/Test project limit. Please upgrade any Unpublishable/Test project's status to Publishable/Live.`,
    };
  } else {
    return {
      success: false,
      // message: `You have reached projects limit. Please contact us.`,
      message: `You have exhausted the project limit. Please contact us for further assistance.`,
    };
  }
};
