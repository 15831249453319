import {
  HOME_VERIFY_TOTP_CODE_BEGIN,
  HOME_VERIFY_TOTP_CODE_SUCCESS,
  HOME_VERIFY_TOTP_CODE_FAILURE,
  HOME_VERIFY_TOTP_CODE_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import { errorLogOut } from '../../common/utils/auth';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

export function verifyTotpCode(args = {}) {
  return (dispatch) => {
    dispatch({
      type: HOME_VERIFY_TOTP_CODE_BEGIN,
    });

    const options = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    };

    const { formData } = args;
    const promise = new Promise((resolve, reject) => {
      console.log('formData', formData);
      const doRequest = axios.post(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}totp/verify`,
        formData,
        options,
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_VERIFY_TOTP_CODE_SUCCESS,
            data: res.data,
          });
          resolve(res.data);
        },
        (err) => {
          dispatch({
            type: HOME_VERIFY_TOTP_CODE_FAILURE,
            data: { error: err },
          });
          reject(err);
          if (err.response.status === 401) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Session Expired',
            }).then((result) => {
              if (result.value) {
                errorLogOut();
              }
            });
          } else {
            toast.error('Oops! Something went wrong');
          }
        },
      );
    });

    return promise;
  };
}

export function dismissVerifyTotpCodeError() {
  return {
    type: HOME_VERIFY_TOTP_CODE_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_VERIFY_TOTP_CODE_BEGIN:
      return {
        ...state,
        verifyTotpCodePending: true,
        verifyTotpCodeError: null,
      };

    case HOME_VERIFY_TOTP_CODE_SUCCESS:
      return {
        ...state,
        verifyTotpCodePending: false,
        verifyTotpCodeError: null,
      };

    case HOME_VERIFY_TOTP_CODE_FAILURE:
      return {
        ...state,
        verifyTotpCodePending: false,
        verifyTotpCodeError: action.data.error,
      };

    case HOME_VERIFY_TOTP_CODE_DISMISS_ERROR:
      return {
        ...state,
        verifyTotpCodeError: null,
      };

    default:
      return state;
  }
}
