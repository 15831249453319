import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ContactUsModal from './ContactUsModal';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';


export class Calculator extends Component {
  static propTypes = {
    login: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };
  state = {
    categories: [],
    features: [],
    selectedCategory: null,
    addedFeatures: {},
    estimateFeatureId: null,
    estimatedFeatures: [],
    totalCost: 0,
    totalDays: 0,
    count: 0,
    searchQuery: '',
    loadingFeatureId: null,
  };
  async componentDidMount() {
    const { actions } = this.props;
    // await actions.createEstimateFeature();
    const { login } = this.props;
    const { createEstimateFeature } = login;
    // if (createEstimateFeature.uuid) {
    //   this.handleAddFeature(
    //     {
    //       uuid: '4oidif73b4032l15320q196cox76',
    //       name: 'Basic Multi-Tenant Project Setup'
    //     }
    //   );
    // }
    actions.getCategories();
    actions.getFeatures();
    document.title = 'Software Development Cost Calculator | DrapCode';
    this.newAddedFeature = null;
  }
  handleCategoryClick = (e, uuid) => {
    e.preventDefault();
    this.setState({ selectedCategory: uuid });
  };
  handleSearchChange = (e) => {
    this.setState({ searchQuery: e.target.value });
  };
  handleAddFeature = async (feature, featureName) => {
    const { addedFeatures } = this.state;
    const { actions } = this.props;

    if (addedFeatures[featureName]) {
      console.log(`Feature ${featureName} is already added.`);
      return;
    }

    const { login } = this.props;
    const { createEstimateFeature } = login;
    let estimateFeatureId = createEstimateFeature?.uuid;

    if (!estimateFeatureId) {
      try {
        await actions.createEstimateFeature();
        const { login } = this.props;
        estimateFeatureId = login.createEstimateFeature?.uuid;
        if (!estimateFeatureId) {
          console.error('Failed to create estimate feature');
          return;
        }
      } catch (error) {
        console.error('Error creating estimate feature:', error);
        toast.error("There was an error creating the estimate feature.");
        return;
      }
    }

    const body = {
      estimateFeatureId,
      featureId: feature.uuid,
    };

    this.setState({ loadingFeatureId: feature.uuid });

    try {
      const addResponse = await actions.addFeature({ body });

      const estimateFeatureResponses = await actions.getLineItems({ estimateFeatureId });

      const lineitemuuid = estimateFeatureResponses[0]?.uuid;

      this.setState(
        (prevState) => {
          const updatedAddedFeatures = {
            ...prevState.addedFeatures,
            [featureName]: lineitemuuid,
          };
          return { addedFeatures: updatedAddedFeatures };
        },
        () => {
          this.fetchEstimatedFeatures();
        }
      );

      toast.success(`The feature ${featureName || feature.name} has been added!`);
    } catch (error) {
      console.error('Error adding feature:', error);
      toast.error("There was an error adding the feature.");
    } finally {
      this.setState({ loadingFeatureId: null });
    }
  };
  handleRemoveFeature = async (lineItemUuid) => {
    try {
      const { login, actions } = this.props;
      // console.log("here", login);
      const { createEstimateFeature, getEstimatedFeatures } = login;
      const { uuid: estimateFeatureId } = createEstimateFeature;

      if (!estimateFeatureId) {
        console.error('Estimate feature ID is not available');
        return;
      }

      this.setState({ loadingFeatureId: lineItemUuid }, () => {
        // console.log("loading feature id", this.state.loadingFeatureId); 
      });

      const featureToRemove = getEstimatedFeatures.find(feature => feature.uuid === lineItemUuid);

      if (!featureToRemove) {
        console.error('Feature not found');
        return;
      }

      const featureNameToRemove = featureToRemove.name;
      // console.log(`Removing feature: ${featureNameToRemove}`);

      // delete call for lineItem
      const removeResponses = await actions.removeLineItem({ lineItemUuid, estimateFeatureId });

      // console.log('Feature removed successfully');

      this.setState((prevState) => {
        //  addedFeatures object without the removed feature
        const updatedAddedFeatures = { ...prevState.addedFeatures };
        delete updatedAddedFeatures[featureNameToRemove];

        // Filter out the removed feature from estimatedFeatures
        const updatedEstimatedFeatures = prevState.estimatedFeatures.filter(
          (feature) => feature.uuid !== lineItemUuid,
        );

        const totalCost = updatedEstimatedFeatures.reduce((acc, feature) => acc + feature.cost, 0);
        const totalDays = updatedEstimatedFeatures.reduce(
          (acc, feature) => acc + feature.duration,
          0,
        );

        return {
          estimatedFeatures: updatedEstimatedFeatures,
          addedFeatures: updatedAddedFeatures,
          totalCost,
          totalDays,
        };
      }, this.fetchEstimatedFeatures);
      toast.success(`The feature ${featureNameToRemove} has been removed!`, {
        position: "top-right",
        className: "rounded-3",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: {
          marginTop: '60px',
        },
      });
    } catch (error) {
      console.error('Error removing feature:', error);
      toast.error("There was an error removing the feature.", {
        position: "top-right",
        className: "rounded-3",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: {
          marginTop: '60px',
        },
      });
    }
    finally {
      this.setState({ loadingFeatureId: null });
    }
  };
  fetchEstimatedFeatures = async () => {
    const { login } = this.props;
    const { createEstimateFeature } = login;
    const { uuid: estimateFeatureId } = createEstimateFeature;
    this.setState({ estimateFeatureId });

    if (!estimateFeatureId) return;

    const { actions } = this.props;
    await actions.getEstimatedFeatures({ estimateFeatureId });
    const { getEstimatedFeatures } = login;
    if (getEstimatedFeatures.length) {
      // console.log("Fetched updated features:", getEstimatedFeatures);
    }
  };
  categoryClose = () => {
    this.setState({ showCategory: false });
  };
  categoryShow = () => {
    this.setState({ showCategory: true });
  };
  modalClose = () => {
    this.setState({ showModal: false });
  };
  modalShow = () => {
    this.setState({ showModal: true });
  };

  render() {
    const { selectedCategory, addedFeatures, showModal, searchQuery, loading } = this.state;
    const { login } = this.props;
    const { categories, features, getEstimatedFeatures } = login;
    // console.log("res here is this.props calculator render", this.props);
    const totalCost =
      getEstimatedFeatures && getEstimatedFeatures.reduce((acc, feature) => acc + feature.cost, 0);
    const totalDays =
      getEstimatedFeatures &&
      getEstimatedFeatures.reduce((acc, feature) => acc + feature.duration, 0);

    const filteredFeatures = selectedCategory
      ? features.filter((feature) => feature.category.uuid === selectedCategory)
      : features;

    const searchedFeatures = filteredFeatures.filter(feature =>
      feature.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    return (
      <div>

        {/* navbar area  */}
        <Navbar expand="lg" sticky="top">
          <Container>
            <Navbar.Brand href="https://drapcode.com/">
              <img
                src="/navbarLogo/drapcode-logo.svg"
                style={{ height: '55px' }}
                alt="Drapcode Logo"
                className="my-auto mx-2 mb-1"
              />
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                {/* Other navigation links can go here */}
              </Nav>
              <Nav className="ms-auto">
                <Link to="/"
                ><a className="btn btn-outline-dark mb-0 me-1 py-1 align-tems-end " style={{ fontSize: '15px' }}>Login</a></Link>
                <Link to="/register"><a className="btn btn-primary mb-0 me-1 py-1 align-tems-end mx-3" style={{ fontSize: '15px' }}>Sign up</a></Link>

              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <ContactUsModal
          showModal={showModal}
          handleClose={this.modalClose}
          totalCost={totalCost}
          handleSubmit={this.handleSubmit}
          estimateFeatureId={this.state.estimateFeatureId}
          actions={actions}
        />
        <div className='section container-fluid' style={{ backgroundColor: '#FAF9F6' }}>
          <div className="container">
            <div className="row">
              <div className="mt-5 text-center">
                <h1 className='mb-2'>Software Development Cost Calculator <span className='text-sm'>by DrapCode Experts</span>
                </h1>
                <p className='lead text-dark'>With the help of DrapCode's skilled team of certified no-code developers, you can quickly construct your web application and launch it for your end consumers.</p>
              </div>
              {/* features section */}
              <div className="col-md-7 mt-3 bg-white border rounded-3">
                <h4 className='mx-3 mt-4 mb-2'>Choose from below listed features to get your cost estimated</h4>

                <div className=' d-flex justify-content-start mx-3'>
                  <input
                    type="text"
                    className="form-control w-45 border bg-white px-2 mt-2"
                    placeholder="Search here...."
                    value={searchQuery}
                    onChange={this.handleSearchChange}
                  />

                </div>
                <div className=" px-3">
                  <div className="row mt-5" style={{ overflow: 'auto', scrollbarWidth: 'thin' }}>
                    {searchedFeatures.map((feature) => {
                      if (feature.name === "Basic Multi-Tenant Project Setup") {
                        return null;
                      }
                      return (
                        <div key={feature.uuid} className="col-md-4 mb-4">
                          <div
                            className="card shadow-none border "
                            style={{
                              backgroundColor: 'white',
                              border: 'none',
                              borderRadius: '15px',
                              Height: '400px',
                            }}
                          >
                            <div className="ps-3 mt-2">
                              <div className="icon icon-shape icon-md text-center rounded-circle">
                                <img
                                  src={`/icons/${feature.iconName}.png`}
                                  alt="Icon | Drapcode"
                                  style={{ height: '40px' }}
                                />
                              </div>
                            </div>
                            <div className="card-body">

                              <h5 className="card-title " style={{ overflow: 'hidden', fontSize: '18px', height: '50px' }}>
                                {feature.name}</h5>
                              <p className="card-text" style={{ height: '80px', fontSize: '13px', scrollbarWidth: 'thin' }}>{feature.description}</p>
                              <hr />
                              <div className="row">
                                <div className="col-md-6">
                                  <h2
                                    className="text-start"
                                    style={{ fontSize: '17px', marginBottom: '0' }}
                                  >
                                    ${feature.cost === 0 ? " Custom" : feature.cost}
                                  </h2>
                                  <p className="text-muted  mx-auto" style={{ fontSize: '13px' }}>
                                    <b>Costs</b>
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <h2
                                    className="text-end"
                                    style={{ fontSize: '17px', marginBottom: '0' }}
                                  >
                                    {feature.duration} Days
                                  </h2>
                                  <p className="text-muted text-end" style={{ fontSize: '13px' }}>
                                    <b>Duration</b>
                                  </p>
                                </div>
                              </div>
                              {/* buttons  */}
                              <a
                                href="#"
                                className="btn d-flex justify-content-center w-100"
                                style={{
                                  backgroundColor: addedFeatures[feature.name] ? '#BF0000' : '#1AA6AD',
                                  color: 'white',
                                  borderRadius: '25px',
                                }}
                                onClick={async (e) => {
                                  e.preventDefault();

                                  const basicFeature = {
                                    uuid: '4oidif73b4032l15320q196cox76',
                                    name: 'Basic Multi-Tenant Project Setup'
                                  };

                                  // Add the basic feature only once if it's not already added
                                  if (!this.state.addedFeatures[basicFeature.name]) {
                                    await this.handleAddFeature(basicFeature, basicFeature.name);
                                    this.setState(prevState => ({
                                      addedFeatures: {
                                        ...prevState.addedFeatures,
                                        [basicFeature.name]: true,
                                      }
                                    }));
                                  }

                                  // Handle adding/removing other features
                                  if (this.state.addedFeatures[feature.name]) {
                                    const estimatedFeature = getEstimatedFeatures.find(f => f.name === feature.name);
                                    if (estimatedFeature) {
                                      await this.handleRemoveFeature(estimatedFeature.uuid);
                                      this.setState(prevState => ({
                                        addedFeatures: {
                                          ...prevState.addedFeatures,
                                          [feature.name]: false,
                                        }
                                      }));
                                    }
                                  } else {
                                    await this.handleAddFeature(feature, feature.name);
                                    this.setState(prevState => ({
                                      addedFeatures: {
                                        ...prevState.addedFeatures,
                                        [feature.name]: true,
                                      }
                                    }));
                                  }
                                }}

                                disabled={this.state.loadingFeatureId === feature.uuid}
                              >
                                {this.state.loadingFeatureId === feature.uuid ? (
                                  <>
                                    <Spinner animation="border" size="sm" role="status" />
                                    <span className="visually-hidden">Loading...</span>
                                  </>
                                ) : (
                                  addedFeatures[feature.name] ? 'Remove' : 'Add'
                                )}
                              </a>

                            </div>
                          </div>
                        </div>
                      )

                    })}
                  </div>
                </div>
              </div>
              {/* line item section  */}
              <div className="col-md-5">

                <div className="container rounded pt-2 bg-white  border d-flex flex-column justify-content-between" style={{ position: 'sticky', top: '110px', marginTop: '16px' }}  >
                  <div className="row">
                    <div className="table-responsive" style={{ overflowY: 'scroll', height: '50vh', scrollbarWidth: 'none' }}
                    >
                      <table className="table border rounded-3 text-dark">
                        <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
                          <tr>
                            <th>Features</th>
                            <th>Days</th>
                            <th>Cost</th>
                            <th></th>
                          </tr>
                          <tr></tr>
                        </thead>
                        <tbody>
                          {getEstimatedFeatures.map((feature, index) => (
                            <tr key={feature.uuid}>
                              <td style={{ paddingLeft: '1.5rem' }}>{feature.name}</td>
                              <td style={{ paddingLeft: '1.5rem' }}>{feature.duration}</td>
                              <td style={{ paddingLeft: '1.5rem' }}>{feature.cost === 0 ? " Custom" : `${feature.cost}`}</td>
                              <td>
                                <a>
                                  <img
                                    src="/icons/delete.png"
                                    style={{ height: '25px', width: '25px', cursor: 'pointer' }}
                                    alt="Icon "
                                    className="me-3 hover-overlay"
                                    onClick={() => {
                                      if (feature.name !== 'Basic Multi-Tenant Project Setup') {
                                        this.handleRemoveFeature(feature.uuid);
                                      } else {
                                        toast.error("This is a default feature, and cannot be removed", {
                                          position: "top-right",
                                          className: "rounded-3",
                                          autoClose: 2500,
                                          hideProgressBar: true,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          style: {
                                            marginTop: '60px',
                                          },
                                        });
                                      }
                                    }}

                                  />
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <hr className="horizontal dark my-3" />
                    <div className='row mt-3 mb-4'>
                      <div className="col-md-6 text-start ">
                        <h5 className="mx-1">Tentative Time: {totalDays} Days </h5>
                      </div>
                      <div className="col-md-6 text-end">
                        <h5>Approx Price: ${totalCost}</h5>
                      </div>
                    </div>
                    <div className='row d-flex justify-content-center'>
                      <div className='btn w-95' onClick={this.modalShow} style={{ fontSize: '16px', backgroundColor: '#1AA6AD', color: 'white' }}>get a quote</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    login: state.login,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Calculator);
