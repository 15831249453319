import initialState from './initialState';
import { reducer as loginReducer } from './login';
import { reducer as signUpReducer } from './signUp';
import { reducer as forgotPasswordReducer } from './forgotPassword';
import { reducer as resetPasswordReducer } from './resetPassword';
import { reducer as confirmClientRegisterReducer } from './confirmClientRegister';
import { reducer as getCategoriesReducer } from './getCategories';
import { reducer as getFeaturesReducer } from './getFeatures';
import { reducer as createEstimateFeatureReducer } from './createEstimateFeature';
import { reducer as getEstimatedFeaturesReducer } from './getEstimatedFeatures';
import { reducer as addFeatureReducer } from './addFeature';
import { reducer as getLineItemsReducer } from './getLineItems';
import { reducer as removeLineItemReducer } from './removeLineItem';
import { reducer as sendMailReducer } from './sendMail';
import { reducer as verifyTotpCodeReducer } from '../../home/redux//verifyTotpCode';

const reducers = [
  loginReducer,
  signUpReducer,
  forgotPasswordReducer,
  resetPasswordReducer,
  confirmClientRegisterReducer,
  getCategoriesReducer,
  getFeaturesReducer,
  createEstimateFeatureReducer,
  getEstimatedFeaturesReducer,
  addFeatureReducer,
  getLineItemsReducer,
  removeLineItemReducer,
  sendMailReducer,
  verifyTotpCodeReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
