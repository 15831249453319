import {
  HOME_LOAD_TOTP_DATA_BEGIN,
  HOME_LOAD_TOTP_DATA_SUCCESS,
  HOME_LOAD_TOTP_DATA_FAILURE,
  HOME_LOAD_TOTP_DATA_DISMISS_ERROR,
} from './constants';
import axios from 'axios';
import { builderAuthHeader, errorLogOut } from '../../common/utils/auth';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

export function loadTotpData(args = {}) {
  return (dispatch) => {
    dispatch({
      type: HOME_LOAD_TOTP_DATA_BEGIN,
    });

    const config = builderAuthHeader();
    const promise = new Promise((resolve, reject) => {
      const doRequest = axios.get(
        `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}totp/setup`,
        config,
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: HOME_LOAD_TOTP_DATA_SUCCESS,
            data: res.data,
          });
          resolve(res.data);
        },
        (err) => {
          dispatch({
            type: HOME_LOAD_TOTP_DATA_FAILURE,
            data: { error: err },
          });
          reject(err);
          if (err.response.status === 401) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Session Expired',
            }).then((result) => {
              if (result.value) {
                errorLogOut();
              }
            });
          } else if (err.data && err.data.message) {
            toast.error(err.data.message);
          } else {
            toast.error('Oops! Something went wrong');
          }
        },
      );
    });

    return promise;
  };
}

export function dismissLoadTotpDataError() {
  return {
    type: HOME_LOAD_TOTP_DATA_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_LOAD_TOTP_DATA_BEGIN:
      return {
        ...state,
        loadTotpDataPending: true,
        loadTotpDataError: null,
      };

    case HOME_LOAD_TOTP_DATA_SUCCESS:
      return {
        ...state,
        loadTotpDataPending: false,
        loadTotpDataError: null,
        totpData: action.data,
      };

    case HOME_LOAD_TOTP_DATA_FAILURE:
      return {
        ...state,
        loadTotpDataPending: false,
        loadTotpDataError: action.data.error,
      };

    case HOME_LOAD_TOTP_DATA_DISMISS_ERROR:
      return {
        ...state,
        loadTotpDataError: null,
      };

    default:
      return state;
  }
}
