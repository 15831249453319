import React from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getOrgSubscriptionLabel, showProjectPlan } from '../../common/constants';
import HTMLReactParser from 'html-react-parser';

const ProjectStats = ({ orgClient, project, changeProjectStatus }) => {
  const changeStatusOfProject = () => {
    console.log('project.projectState :>> ', project.projectState);
    let swalMessage = '';
    let icon = '';
    let title = '';
    const { projectType, projectState } = project || '';
    const { subscription } = orgClient || '';
    const projectPlan = showProjectPlan(project);
    const orgPlan = getOrgSubscriptionLabel(subscription);
    console.log(
      '🚀 ~ file: ProjectStats.js:14 ~ changeStatusOfProject ~ projectPlan:',
      projectPlan,
      'projectType:',
      projectType,
      'orgPlan:',
      orgPlan,
      'subscription:',
      subscription,
    );
    if (projectState === 'FREE') {
      icon = 'success';
      title = 'Are you upgrading?';
      swalMessage = `<div class="text-center">Upgrading this project to <b>Publishable/Live</b> will unlock team access and additional features included in the <em>${orgPlan}</em> subscription.</div>`;
    } else {
      icon = 'warning';
      title = 'Are you downgrading?';
      swalMessage = `<div class="text-center">Downgrading this project to <b>Unpublishable/Test</b> will remove the team access and other premium features included in the <em>${orgPlan}</em> subscription.</div>`;
    }

    Swal.fire({
      icon: icon,
      title: title,
      showCancelButton: true,
      confirmButtonText: `Confirm`,
      html: swalMessage,
    }).then((result) => {
      if (result.value) {
        changeProjectStatus({
          projectId: project.uuid,
          status: projectState === 'FREE' ? 'PAID' : 'FREE',
        });
      }
    });
  };
  const getProjectStatusMessage = () => {
    let message = '';
    const { projectState } = project || '';
    const { subscription } = orgClient || '';
    const orgPlan = getOrgSubscriptionLabel(subscription);
    if (subscription === 'BUILDER_FREE') {
      message = `<span class="text-start">This account does not have any subscription plan.<br/>&nbsp;Please upgrade your account to access premium features.</span>`;
    } else {
      if (projectState === 'FREE') {
        message = `<span class="text-start">Your account have a <em>${orgPlan}</em> subscription.</span>`;
        message += `<br/><div class="mt-3"><b>Please note:</b> You can unlock team access and additional premium features by upgrading this project to <b>Publishable/Live</b>.</div>`;
      } else {
        message = `<span class="text-start">Your account have a <em>${orgPlan}</em> subscription.</span>`;
        message += `<br/><div class="mt-3"><b>Please note:</b> Downgrading this project's status to <b>Unpublishable/Test</b> will remove team access and other premium features from this project</div>`;
      }
    }
    return message;
  };
  return (
    <>
      <div className="card-header">
        <h4>Project Status</h4>
      </div>
      <hr className="dark horizontal " style={{ marginTop: '-12px' }} />
      <div className="card-body">
        {orgClient.subscription === 'BUILDER_FREE' && (
          <>
            <div className="alert alert-warning rounded text-white align-items-center">
              <i className="material-icons">warning</i>&nbsp;
              {HTMLReactParser(getProjectStatusMessage())}
            </div>
          </>
        )}
        {orgClient.subscription === 'BUILDER_FREE' ? (
          <>
            <hr className="dark horizontal " />
            <Link to={`/accounts`} className="btn btn-sm btn-outline-danger">
              Upgrade Account
            </Link>
          </>
        ) : (
          <>
            <div className="mb-3">
              <i className="material-icons">info</i>&nbsp;
              {HTMLReactParser(getProjectStatusMessage())}
            </div>
            <hr className="dark horizontal " />
            <button
              type="button"
              className={`btn btn-sm ${
                project.projectState === 'FREE' ? 'btn-outline-dark' : 'btn-outline-danger'
              }`}
              onClick={changeStatusOfProject}
            >
              Change to {project.projectState === 'FREE' ? 'Publishable' : 'Unpublishable'}
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default ProjectStats;
