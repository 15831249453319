const initialState = {
  signUpPending: false,
  signUpError: null,
  loginAuthPending: false,
  loginAuthError: null,
  projectMetadata: null,
  clientDetail: {},
  createProject: {},
  project: {},
  teamMemberList: {},
  orgClient: {},
  clientProjects: {},
  checkCloning: {},
  templatesData: {},
  requestStatus: '',
  pendingInvites: [],
  memberOrgs: [],
  orgMembers: [],
  lineItems: [],
  totpData: [],
  lineItemMetadata: null,
  afterPaymentResponse: null,
  projectStats: null,
  invoices: [],
  invoiceMetadata: null,
  getAllProjectsPending: true,
  getAllProjectsError: null,
  clientDetailsPending: false,
  clientDetailsError: null,
  createClientProjectPending: false,
  createClientProjectError: null,
  fetchProjectPending: false,
  fetchProjectError: null,
  deleteClientProjectPending: false,
  deleteClientProjectError: null,
  updateClientProjectPending: false,
  updateClientProjectError: null,
  changeProjectPermissionPending: false,
  changeProjectPermissionError: null,
  transferOwnershipPending: false,
  transferOwnershipError: null,
  inviteTeamMemberPending: false,
  inviteTeamMemberError: null,
  teamMemberListPending: false,
  teamMemberListError: null,
  getVersionsPending: false,
  getVersionsError: null,
  teamMemberPendingRequestsPending: false,
  teamMemberPendingRequestsError: null,
  removeTeamMemeberPending: false,
  removeTeamMemeberError: null,
  projectActivitiesPending: false,
  projectActivitiesError: null,
  clientOrganizationPending: false,
  clientOrganizationError: null,
  updateProfilePending: false,
  updateProfileError: null,
  deleteClientProfilePending: false,
  deleteClientProfileError: null,
  forgotPasswordPending: false,
  forgotPasswordError: null,
  clientProjectsPending: false,
  clientProjectsError: null,
  getTemplatesPending: false,
  getTemplatesError: null,
  checkCloningPending: false,
  checkCloningError: null,
  cloneProjectPending: false,
  cloneProjectError: null,
  cloningRequestPending: false,
  cloningRequestError: null,
  resetPasswordPending: false,
  resetPasswordError: null,
  clonePendingRequestPending: false,
  clonePendingRequestError: null,
  projectCloneStatusPending: false,
  projectCloneStatusError: null,
  clientPendingRequestPending: false,
  clientPendingRequestError: null,
  stripePaymentPending: false,
  stripePaymentError: null,
  changeTeamMemberRequestPending: false,
  changeTeamMemberRequestError: null,
  loadPendingInvitePending: false,
  loadPendingInviteError: null,
  changeRequestStatusPending: false,
  changeRequestStatusError: null,
  loadMemberOrgsPending: false,
  loadMemberOrgsError: null,
  loadOrgMembersPending: false,
  loadOrgMembersError: null,
  fetchInvoicePending: false,
  fetchInvoiceError: null,
  fetchLineItemsPending: false,
  fetchLineItemsError: null,
  verifyPaymentStatusPending: false,
  verifyPaymentStatusError: null,
  changeProjectStatusPending: false,
  changeProjectStatusError: null,
  hostingProjectSubscriptionPending: false,
  hostingProjectSubscriptionError: null,
  cancelUpgradePending: false,
  cancelUpgradeError: null,
  loadProjectStatsPending: false,
  loadProjectStatsError: null,
  loadTotpDataPending: false,
  loadTotpDataError: null,
  verifyTotpCodePending: false,
  verifyTotpCodeError: null,
  deleteTotpPending: false,
  deleteTotpError: null,
};

export default initialState;
