import React from 'react';

const OrganizationDetail = ({ organization, clientDetail }) => {
  const { owner, projects, name } = organization;
  const { firstName, lastName } = owner;
  const fProjects = projects.filter((project) =>
    project.members.find((member) => member.username === clientDetail.username),
  );

  return (
    <tr>
      <td className="align-middle text-center">
        {firstName} {lastName}
      </td>
      <td className="align-middle text-center d-flex flex-sm-wrap gap-1">
        {fProjects &&
          fProjects.map((project) => (
            <span className="badge badge-growth-plan w-sm-auto">{project.name}</span>
          ))}
      </td>
    </tr>
  );
};

export default OrganizationDetail;
