import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import drapcodeLogo from '../../assets/img/logos/drapcode-logo-bluegreen.svg';
import DashboardFooter from '../home/Footer';
import { Field, Formik, Form } from 'formik';
import * as Yup from 'yup';
import CustomLoader from '../common/CustomLoader';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';

export class LandingTwoFactorAuthentication extends Component {
  static propTypes = {
    login: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  allowAuthenticateUser = () => {
    const keys = ['token', 'role', 'uuid', 'name', 'country'];

    keys.forEach((key) => {
      let storedValue = localStorage.getItem(key);
      if (storedValue) {
        cookie.save(key, storedValue, { maxAge: 10000 * 60, path: '/' });
        this.props.login[key] = storedValue;
      }
    });

    localStorage.clear();
    window.location.href = '/dashboard';
  };

  handleSubmit = (values, { resetForm }) => {
    let { codeInput } = values;
    const { actions } = this.props;
    let formData = new FormData();
    formData.append('code', codeInput);
    formData.append('enableTotp', false);
    formData.append('username', cookie.load('username'));
    actions.verifyTotpCode({ formData }).then((res) => {
      if (res.code === 200) this.allowAuthenticateUser();
      else if (res.code === 404) toast.error(res.message);
    });
    resetForm();
  };

  render() {
    const { verifyTotpCodePending } = this.props.login;
    return (
      <main className="main-content mt-0">
        <section>
          <div className="page-header min-vh-100">
            <div className="container">
              <div className="row">
                <a href="https://drapcode.com/" target="_blank">
                  <div className="col-md-12">
                    <img src={drapcodeLogo} alt="Logo" className="custom-logo" />
                  </div>
                </a>
                <div className="col-md-12">
                  <div className="col-xl-4 col-lg-5 col-md-7 mx-auto mt-5">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex justify-content-center">
                          <i className="material-icons" style={{ fontSize: '-webkit-xxx-large' }}>
                            lock
                          </i>
                        </div>
                        &nbsp;
                        <h5 className="f3 text-normal text-center">Authentication code</h5>
                        <Formik
                          initialValues={{
                            codeInput: '',
                          }}
                          validationSchema={Yup.object({
                            codeInput: Yup.string()
                              .matches(/^[0-9]{6}$/, 'Invalid Code!')
                              .required('Code is required'),
                          })}
                          onSubmit={this.handleSubmit}
                        >
                          {({ values, errors, touched }) => (
                            <Form>
                              <div className="input-group input-group-outline mb-3">
                                <Field
                                  autoComplete="off"
                                  type="text"
                                  id="codeInput"
                                  value={values.codeInput}
                                  name="codeInput"
                                  placeholder="******"
                                  className={`form-control ${
                                    touched.codeInput && errors.codeInput ? 'is-invalid' : ''
                                  }`}
                                />
                                {touched.codeInput && errors.codeInput && (
                                  <div className="invalid-feedback">{errors.codeInput}</div>
                                )}
                              </div>
                              <div className="text-center">
                                <button
                                  type="submit"
                                  className="btn btn-lg btn-primary btn-lg w-100 mt-4 mb-0"
                                >
                                  Verify
                                  <CustomLoader loading={verifyTotpCodePending} />
                                </button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                      <div className="card-footer text-center pt-0 px-lg-2 px-1">
                        <p className="mb-2 text-sm mx-auto">
                          Open your two-factor authenticator (TOTP) app or browser extension to view
                          your authentication code.
                        </p>
                        <p className="mb-2 text-sm mx-auto">
                          <div className="text-primary font-weight-bold">Locked Out?</div>
                          <p>
                            Please reach out to &nbsp;
                            <a
                              data-v-60a0011f=""
                              href="mailto:support@drapcode.com"
                              class="text-primary"
                            >
                              support@drapcode.com
                            </a>
                            &nbsp; to request the unlinking of your account from two-factor
                            authentication.
                          </p>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="fixed-bottom">
          <DashboardFooter />
        </div>
      </main>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    login: state.login,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingTwoFactorAuthentication);
