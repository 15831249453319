import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import Layout from './Layout';
import { Field, Formik, Form } from 'formik';
import BeatLoader from 'react-spinners/BeatLoader';
import CustomLoader from '../common/CustomLoader';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import cookie from 'react-cookies';

export class TwoFactorAuthentication extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { actions } = this.props;
    actions.loadTotpData();
  }

  handleSubmit = (values, { resetForm }) => {
    let { codeInput } = values;
    const { actions, history } = this.props;
    const { totpData } = this.props.home;
    let formData = new FormData();
    formData.append('code', codeInput);
    formData.append('enableTotp', true);
    formData.append('username', cookie.load('username'));
    formData.append('secretKey', totpData.secretKey);
    actions.verifyTotpCode({ formData }).then((res) => {
      if (res.code === 200) {
        toast.success(res.message);
        history.push('/profile');
      } else if (res.code === 404) toast.error(res.message);
    });
    resetForm();
  };

  render() {
    const { totpData, loadTotpDataPending, verifyTotpCodePending, clientDetail } = this.props.home;
    const { isTotpEnabled } = clientDetail;
    return (
      <Layout currentPage="Two Factor Authentication">
        <div className="mt-5">
          <div className="mx-3 mx-md-4">
            <div className="row gx-4 mb-2">
              <div className="col-12 col-xl-10 offset-xl-1">
                <div className="card card-plain shadow-lg">
                  <div className="card-body">
                    <h5 className="font-weight-bolder">
                      {isTotpEnabled ? 'Update' : 'Enable'} two-factor authentication (2FA)
                    </h5>
                    <hr className="dark horizontal my-4" />
                    <h5 className="mb-3">
                      {' '}
                      {isTotpEnabled ? 'Authenticator App' : 'Setup authenticator app'}
                    </h5>
                    <div className="text-justify">
                      Authenticator apps and browser extensions like 1Password, Authy, Microsoft
                      Authenticator, etc. generate one-time passwords that are used as a second
                      factor to verify your identity when prompted during sign-in.
                    </div>
                    <h5 className="mb-3 mt-3">Scan the QR code</h5>
                    <div className="text-justify">
                      Use an authenticator app or browser extension to scan.
                      <CustomLoader
                        size="6px"
                        color="#303453"
                        sync={true}
                        loading={loadTotpDataPending}
                      />
                      <img
                        src={totpData.qrImage}
                        alt="qr code"
                        style={{
                          display: 'block',
                          height: 'auto',
                          width: 'auto',
                          maxWidth: '100%',
                        }}
                      />
                      <div className="mt-3 mb-3">
                        Unable to scan? You can use the code <b>{totpData.secretKey}</b> manually
                        configure your authenticator app.
                      </div>
                    </div>
                    <Formik
                      initialValues={{
                        codeInput: '',
                      }}
                      validationSchema={Yup.object({
                        codeInput: Yup.string()
                          .matches(/^[0-9]{6}$/, 'Invalid Code!')
                          .required('Code is required'),
                      })}
                      onSubmit={this.handleSubmit}
                    >
                      {({ values, errors, touched }) => (
                        <Form encType="multipart/form-data" className="profile-form">
                          <React.Fragment>
                            <label htmlFor="businessName" className="form-label">
                              Verify the code from the app
                            </label>
                            <Field
                              autoComplete="off"
                              type="text"
                              id="codeInput"
                              value={values.codeInput}
                              name="codeInput"
                              placeholder="******"
                              className="form-control"
                              style={{ width: 'auto' }}
                            />
                            {errors.codeInput && touched.codeInput ? (
                              <div className="text-danger">{errors.codeInput}</div>
                            ) : null}
                          </React.Fragment>
                          <hr className="form-divider" />
                          <div className="text-center">
                            <div className="d-flex" style={{ float: 'right' }}>
                              <Link
                                to={'/profile'}
                                className="btn btn-outline-danger btn-md mt-4 mb-2 ml-5 mx-3"
                                style={{ whiteSpace: 'nowrap' }}
                              >
                                Cancel
                              </Link>
                              <button
                                type="submit"
                                className="btn btn-success btn-md text-white mt-4 mb-2"
                                style={{ whiteSpace: 'nowrap' }}
                              >
                                Continue{' '}
                                <BeatLoader
                                  cssOverride={{ display: '' }}
                                  size={'9px'}
                                  margin={'1px'}
                                  color={'#344767'}
                                  loading={verifyTotpCodePending}
                                />
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorAuthentication);
