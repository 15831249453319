import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import BeatLoader from 'react-spinners/BeatLoader';
import * as actions from './redux/actions';
import ClientProfileForm from './ClientProfileForm';
import Layout from './Layout';
import { Link } from 'react-router-dom';

export class ClientProfile extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };

  handleCountryChange = (selectedOption) => {
    this.setState({ selectedCountry: selectedOption });
  };

  handleSubmit = async (values, { setSubmitting }) => {
    const { actions } = this.props;
    setSubmitting(true);
    const { firstName, lastName, country, businessName } = values;
    const data = {
      firstName,
      lastName,
      country,
      businessName,
    };
    actions.updateProfile({ data }).then(() => setSubmitting(false));
  };

  deleteProfile = async () => {
    const { actions, history } = this.props;
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonText: `Confirm`,
      text: "You're about to permanently delete your account. If you're ready to delete it, click Confirm.",
    }).then((result) => {
      if (result.value) {
        actions.deleteClientProfile().then((res) => {
          history.push('/');
        });
      }
    });
  };

  deleteTotp = (async) => {
    const { actions } = this.props;
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      showCancelButton: true,
      confirmButtonText: `Confirm`,
      text: "You're about to delete two factor authentication. If you're ready to delete it, click Confirm.",
    }).then((result) => {
      if (result.value) {
        actions.deleteTotp().then((res) => {
          actions.clientDetails();
        });
      }
    });
  };

  showForm = () => {
    const { home } = this.props;
    const { updateProfilePending, clientDetail, orgClient, deleteTotpPending } = home;
    const { username, country, firstName, lastName, registerAddress, gst, panNo } = clientDetail;
    const { name } = orgClient;

    let businessName = clientDetail.businessName;
    businessName = businessName || name;

    const initialValues = {
      username,
      firstName: firstName,
      lastName: lastName,
      businessName,
      registerAddress,
      gst,
      panNo,
      country,
    };
    return (
      <ClientProfileForm
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        updateProfilePending={updateProfilePending}
        handleCountryChange={this.handleCountryChange}
        onDeleteProfile={this.deleteProfile}
      />
    );
  };

  render() {
    const { home } = this.props;
    const { clientDetailsPending, clientOrganizationPending, clientDetail, deleteTotpPending } =
      home;
    const { isTotpEnabled } = clientDetail;
    return (
      <Layout currentPage="Profile">
        <div className="mt-5">
          <div className="mx-3 mx-md-4">
            <div className="row gx-4 mb-2">
              <div className="col-12 col-xl-6">
                <div className="card h-100 shadow-lg">
                  <div className="card-header pb-0 p-3">
                    <div className="row">
                      <div className="col-md-8 d-flex align-items-center">
                        <h4 className="mb-0">Profile Information</h4>
                      </div>
                    </div>
                  </div>
                  <hr className="dark horizontal my-3" />
                  <div className="card-body">
                    {(clientDetailsPending || clientOrganizationPending) && (
                      <BeatLoader
                        css={{ display: 'inline-block' }}
                        size={'15px'}
                        margin={'5px'}
                        color={'#344767'}
                        loading={clientDetailsPending || clientOrganizationPending}
                      />
                    )}

                    {this.showForm()}
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-6 d-flex flex-column gap-3">
                <div className="card">
                  <div className="card-header">
                    <h4>Two-factor authentication</h4>
                  </div>
                  <hr className="dark horizontal " style={{ marginTop: '-12px' }} />
                  <div className="card-body">
                    <div
                      className={
                        'alert rounded text-white align-items-center ' +
                        (isTotpEnabled ? 'alert-success' : 'alert-warning')
                      }
                    >
                      <i className="material-icons">lock</i>&nbsp;
                      {isTotpEnabled ? (
                        <span className="text-start">
                          Two-factor authentication is enabled in your account.
                          <br />
                          Update using any authentication app or browser extension to get two-factor
                          authentication codes when prompted.
                        </span>
                      ) : (
                        <span className="text-start">
                          Two-factor authentication is not enabled yet.
                          <br />
                          It adds an additional layer of security to your account by requiring more
                          than just a password to sign in.
                        </span>
                      )}
                    </div>
                    <hr className="dark horizontal " />
                    <div className="text-center">
                      <div className="d-flex justify-content-between">
                        {isTotpEnabled ? (
                          <button
                            type="button"
                            className="btn btn-outline-danger btn-md mt-4 mb-2 ml-5"
                            style={{ whiteSpace: 'nowrap' }}
                            onClick={this.deleteTotp}
                          >
                            <i className="material-icons text-lg position-relative">delete</i>
                            Delete Two Factor Authentication
                            <BeatLoader
                              cssOverride={{ display: '' }}
                              size={'7px'}
                              margin={'1px'}
                              color={'#344767'}
                              loading={deleteTotpPending}
                            />
                          </button>
                        ) : (
                          ''
                        )}
                        <Link
                          className="btn btn-success btn-md text-white mt-4 mb-2"
                          to={'/two-factor-authenticate/setup'}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          <i className="material-icons text-lg position-relative">save</i>

                          {isTotpEnabled ? 'Update' : 'Enable'}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientProfile);
