import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import * as actions from './redux/actions';
import InviteTeamMember from './InviteTeamMember';

export class TeamMember extends Component {
  static propTypes = {
    home: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  };
  state = {
    currentPage: 1,
    perPage: 10,
  };
  async componentDidMount() {
    const { currentPage, perPage } = this.state;
    const { home, actions } = this.props;
    const { project } = home;
    const { uuid } = project ? project : {};
    await actions.teamMemberList({ projectId: uuid, page: currentPage - 1, size: perPage });
  }

  renderTableRows = () => {
    const { home } = this.props;
    const { teamMemberList, project } = home;
    const contentArray = teamMemberList && teamMemberList.content ? teamMemberList.content : [];

    return contentArray.map((member) => (
      <tr key={member.id}>
        <td className="align-middle text-center">
          <p className="text-sm font-weight-bold">{member.firstName}</p>
        </td>
        <td className="align-middle text-center">
          <p className="text-sm font-weight-bold">{member.lastName}</p>
        </td>
        <td className="align-middle text-center">
          <p className="text-sm font-weight-bold">{member.username}</p>
        </td>
        <td className="align-middle text-center">
          <button
            className="btn btn-outline-danger btn-sm"
            onClick={() => this.handleDelete(member.uuid)}
          >
            <i className="material-icons text-sm position-relative">delete</i>
            Remove
          </button>
        </td>
      </tr>
    ));
  };

  handleDelete = async (uuid) => {
    const { props, actions } = this.props;
    const { params } = props.match;
    const { projectUuid } = params;
    const { currentPage, perPage } = this.state;

    const result = await Swal.fire({
      icon: 'warning',
      title: `Are you sure about deleting this team member?`,
      showCancelButton: true,
      confirmButtonText: 'Delete',
    });

    if (result.value) {
      try {
        await actions.removeTeamMemeber({ uuid, projectId: projectUuid });
        await actions.teamMemberList({
          projectId: projectUuid,
          page: currentPage - 1,
          size: perPage,
        });
      } catch (error) {
        console.error('Error deleting team member:', error);
      }
    }
  };

  showSubscriptionMessage = () => {
    const { home } = this.props;
    const { orgClient, project, teamMemberList } = home;
    const { subscription } = orgClient;
    if (subscription === 'BUILDER_FREE') {
      return (
        <p className="text-center mt-3">Please upgrade your account to enable this feature.</p>
      );
    } else if (project.projectState === 'FREE') {
      return (
        <p className="text-center mt-3">
          Please upgrade this project to <b>Publishable/Live</b> to enable this feature.
        </p>
      );
    } else {
      const { content } = teamMemberList ? teamMemberList : {};
      if (content && content.length > 0) {
        return (
          <div className="table-responsive p-0">
            <table className="table align-items-center mb-0">
              <thead>
                <tr>
                  <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    First Name
                  </th>
                  <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                    Last Name
                  </th>
                  <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    Username
                  </th>
                  <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>{this.renderTableRows()}</tbody>
            </table>
          </div>
        );
      } else {
        return <p className="text-center mt-3">There are no team members for this project yet!</p>;
      }
    }
  };

  showInviteMember = () => {
    const { home } = this.props;
    const { orgClient, project, teamMemberList } = home;
    const { subscription } = orgClient;
    if (subscription === 'BUILDER_FREE') {
      return '';
    } else if (project.projectState === 'FREE') {
      return '';
    } else {
      return (
        <div className="col-12 col-md-6">
          <InviteTeamMember />
        </div>
      );
    }
  };

  render() {
    const { home } = this.props;
    const { teamMemberList } = home;
    const { content } = teamMemberList ? teamMemberList : {};

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card bg-white mb-4">
              <div className="card-header">
                <h4>Team Member's List</h4>
              </div>
              <div className="card-body px-0 pb-2">{this.showSubscriptionMessage()}</div>
            </div>
          </div>
          {this.showInviteMember()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    home: state.home,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamMember);
