import React from 'react';
import { FREE, getCloudPlanFeature, ProjectPlanOptions } from '../../common/constants';
import HTMLReactParser from 'html-react-parser';

const HostingPlanDetail = ({
  planName,
  price,
  drapcodePlan,
  stripePlan,
  projectType,
  handleUpgrade,
  showContactUs,
}) => {
  const buttonText = () => {
    const projectTypeLevel = projectType ? projectType.split('PAID_')[1] : 0;
    const drapcodePlanLevel = drapcodePlan ? drapcodePlan.split('PAID_')[1] : 0;
    if (!projectType || projectType === FREE || projectTypeLevel < drapcodePlanLevel) {
      return (
        <>
          <i className="material-icons text-lg position-relative">upgrade</i> Upgrade
        </>
      );
    } else if (projectTypeLevel > drapcodePlanLevel) {
      return (
        <>
          <i className="material-icons text-lg position-relative">vertical_align_bottom</i>{' '}
          Downgrade
        </>
      );
    } else {
      return '';
    }
  };
  const showButtonOrMessage = () => {
    if (projectType === drapcodePlan) {
      const planMessage = ProjectPlanOptions.find((plan) => plan.value === projectType);
      return (
        <div>
          <div className="flex align-items-center text-success">
            <i className="material-icons opacity-10 fs-3">check_circle</i> &nbsp;
            <p className="fs-5">
              Subscribed to <b>{planMessage.label}</b>
            </p>
          </div>
        </div>
      );
    } else if (showContactUs) {
      return (
        <div className="d-flex justify-content-center mt-4">
          <a href="https://drapcode.com/contact-us" target="_blank">
            <button type="button" className="btn btn-success text-white">
              <i className="material-icons">mail</i> Contact Us
            </button>
          </a>
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-center mt-4">
          <button
            type="button"
            className="btn btn-success text-white"
            onClick={() => handleUpgrade(drapcodePlan, stripePlan)}
          >
            {buttonText()}
          </button>
        </div>
      );
    }
  };
  return (
    <div className="card card-blog card-plain shadow-lg move-on-hover">
      <div className="card-body p-3">
        <h4 className="mb-3 text-center">{planName}</h4>
        <div className="text-center">
          <h5 className="d-inline-block">
            $<span className="price">{price}</span>
          </h5>
          <h5 className="price-detail-color d-inline-block my-2">/app/month</h5>
          <div className="text-center mb-2">
            {HTMLReactParser(getCloudPlanFeature(drapcodePlan))}
          </div>
          {showButtonOrMessage()}
        </div>
        <hr className="dark horizontal my-3" />
        <div className="text-center mb-3">
          <a href="https://drapcode.com/pricing" target="_blank" className="social-icon">
            <span className="price-color">View Details</span>&nbsp;
            <i className="fas fa-info-circle price-detail-color my-2 text-decoration-none"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default HostingPlanDetail;
