import axios from 'axios';
import {
  LOGIN_SEND_MAIL_BEGIN,
  LOGIN_SEND_MAIL_SUCCESS,
  LOGIN_SEND_MAIL_FAILURE,
  LOGIN_SEND_MAIL_DISMISS_ERROR,
} from './constants';

export function sendMail(args = {}) {
  return (dispatch) => {
    dispatch({
      type: LOGIN_SEND_MAIL_BEGIN,
    });
    // console.log("add feature action", args);
    const { body, estimateFeatureId  } = args;
    
    // console.log("args snemail data", args)
    // const token = process.env.REACT_APP_TOKEN;
    const options = {
      headers: {
        // 'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    const promise = new Promise((resolve, reject) => {
     
      // const doRequest = axios.post(
      //   `${process.env.REACT_APP_ORGANIZATION_ENGINE_URL}line-items/${estimateFeatureId}/quote`,
      //   body,
      //   options,
      // )
            doRequest.then(
        (res) => {
          dispatch({
            type: LOGIN_SEND_MAIL_SUCCESS,
            data: res,
          });
          resolve(res);
          console.log("sendmail reposnes actions",res)
        },
        (err) => {
          dispatch({
            type: LOGIN_SEND_MAIL_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissSendMailError() {
  return {
    type: LOGIN_SEND_MAIL_DISMISS_ERROR,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case LOGIN_SEND_MAIL_BEGIN:
      return {
        ...state,
        sendMailPending: true,
        sendMailError: null,
      };

    case LOGIN_SEND_MAIL_SUCCESS:
      // The request is success
      return {
        ...state,
        sendMailPending: false,
        sendMailError: null,
      };

    case LOGIN_SEND_MAIL_FAILURE:
      return {
        ...state,
        sendMailPending: false,
        sendMailError: action.data.error,
      };

    case LOGIN_SEND_MAIL_DISMISS_ERROR:
      return {
        ...state,
        sendMailError: null,
      };

    default:
      return state;
  }
}
